// Here you can add other styles
.pratinjau-foto .detil-foto {
  width: 250px;
  border-radius: 20px;
}
.pratinjau-foto {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.marker {
  width: 15px;
  height: 40px;
}

.tool-tips {
  background-color: white;
  width: 350px;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  left: 40px;
}

.blue-circle {
  height: 15px;
  width: 15px;
  border-radius: 10px;
  border: 4px solid;
  border-color: #11b1f7;
}

.red-circle {
  height: 15px;
  width: 15px;
  border-radius: 10px;
  border: 4px solid;
  border-color: #e54c4c;
}

.text-bold {
  font-weight: bold;
  font-size: 10pt;
}

.primary-text {
  color: #e54c4c;
}

.text-14 {
  font-size: 14pt;
}

.margin-left-sm {
  margin-left: 5px;
}

.margin-left-lg {
  margin-left: 20px;
}

.margin-top-md {
  margin-top: 10px;
}

.padding-left-md {
  padding-left: 10px !important;
}

.padding-left-lg {
  padding-left: 20px !important;
}

.padding-right-md {
  padding-right: 10px !important;
}

.row-flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.hr {
  width: "100%";
  height: 5px;
  background-color: #dedede;
}

.label-text {
  color: gray;
}

.custom-icon {
  height: 15px;
  width: 15px;
}

.icon-close {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-blue {
  background-color: #11b1f7;
}
